import {
  ApolloClient,
  InMemoryCache,
  from,
  createHttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { getKey, removeKey } from "./helpers/sessionKey";
import { toast } from "react-toastify";

const httpLink: any = createHttpLink({
  uri: "https://dibposservice-v13.dibsolutions.com.au/graphql",
  credentials: "same-origin",
});

const setAuthorizationLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  let token;
  if (getKey("token")) {
    token = getKey("token");
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `JWT ${token}` : null,
    },
  };
});
const errorLink = onError(({ graphQLErrors, networkError }) => {
  // my error handling logic
  if (graphQLErrors) {
    for (const { message, locations, path } of graphQLErrors) {
      if (
        message === "Signature has expired" ||
        message === "Error decoding signature" ||
        message === "Authentication credentials were not provided"
      ) {
        removeKey("token");
      } else {
        if (!window.location.href.includes("qrCode-Order")) {
          toast.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        }
      }
    }
  }
});

const defaultOptions: any = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    // nextFetchPolicy: "cache-only",
  },
};

const apolloClient = new ApolloClient({
  ssrMode: typeof window === "undefined",
  link: from([setAuthorizationLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

export default apolloClient;
