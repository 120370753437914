import React, { useEffect, useState } from "react";
import Loadable from "react-loadable";
import DataLoadSpinner from "../../../components/Reusable/Spinner/index";
import { toast } from "react-toastify";
import {
  DETAIL_SALES_REPORT,
  POS_SCREEN_PRODUCT,
  POS_SCREEN_PRODUCT_CATEGORY,
  QUERY_RESTAURANT_TABLE,
  QUERY_SETTINGS,
  QUERY_TRANSACTION_ACCOUNTS,
} from "../../query";
import { useLazyQuery, useQuery } from "@apollo/client";

const DetailSalesReportComponent: any = Loadable({
  loader: () => import("../../../components/Reports/DetailsSalesReport"),
  loading: DataLoadSpinner,
});
type Props = {};

const DetailSalesReport = (props: Props) => {
  const [categoryDropdown, setCategoryDropdown] = useState<any>([]);
  const [usersDropDown, setusersDropDown] = useState<any>([]);
  const [itemsDropDown, setItemsDropDown] = useState<any>([]);
  const [tablesDropDown, setTablesDropDown] = useState<any>([]);
  const [transactionAccountDropdown, setTransactionAccountDropdown] =
    useState<any>([]);

  const [reportOpenState, setReportOpenState] = useState<boolean>(false);
  const [reportData, setReportData] = useState<any>();
  const [localFormState, setLocalFormState] = useState<any>();

  const { data: settingsData } = useQuery(QUERY_SETTINGS);

  // CATEGORY DROPDOWN LIST
  const { data: categoryData } = useQuery(POS_SCREEN_PRODUCT_CATEGORY, {
    variables: {
      level: 0,
      status: "category",
    },
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    categoryData?.categories?.edges?.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.name,
        altId: elem?.node?.id,
      })
    );
    setCategoryDropdown(dropdownFormat);
  }, [categoryData]);

  // // USERS DROPDOWN LIST
  // const { data: userData } = useQuery(QUERY_USERS);
  // useEffect(() => {
  //   const dropdownFormat: any = [];
  //   userData?.users?.edges?.map((elem: any) =>
  //     dropdownFormat.push({
  //       id: elem?.node?.pk,
  //       title: elem?.node?.username,
  //     })
  //   );
  //   setusersDropDown(dropdownFormat);
  // }, [userData]);

  // PROUDUCT DROPDOWN
  const { data: productData } = useQuery(POS_SCREEN_PRODUCT, {
    variables: {
      categoryList:
        localFormState?.category.length > 0 ? localFormState?.category : null,
    },
  });
  useEffect(() => {
    const productList: any = [];
    if (localFormState?.category.length > 0) {
      productData?.products?.edges?.map((elem: any) =>
        productList.push({
          id: elem?.node?.pk,
          title: elem?.node?.title,
          contentType: elem?.node?.contentType,
        })
      );
    }
    setItemsDropDown(productList);
  }, [productData]);

  // RESTAURANT TABLE DROPDOWN
  const { data: RestaurantTableData } = useQuery(QUERY_RESTAURANT_TABLE);
  useEffect(() => {
    const dropdownFormat: any = [];
    RestaurantTableData?.restaurantTables?.edges?.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.title,
      })
    );
    setTablesDropDown(dropdownFormat);
  }, [RestaurantTableData]);

  // TRANSACTION ACCOUNT DROPDOWN
  const { data: transactionAccount } = useQuery(QUERY_TRANSACTION_ACCOUNTS);
  useEffect(() => {
    const dropdownFormat: any = [];
    transactionAccount?.transactionAccounts?.edges?.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.name,
      })
    );
    setTransactionAccountDropdown(dropdownFormat);
  }, [transactionAccount]);

  // DETAIL SALES REPORT QUERY
  const [generateDetailSalesReport, { loading: detailSalesReportLoading }] =
    useLazyQuery(DETAIL_SALES_REPORT);

  const handleGenerateDetailSalesReport = async (values: any) => {
    const itemsObjList: any[] = [];
    values?.items?.map((elem: any) =>
      itemsObjList.push({
        objectId: elem,
      })
    );
    const response = await generateDetailSalesReport({
      variables: {
        user: values?.user,
        category: values?.category,
        items: itemsObjList,
        table: values?.table,
        paymentMethods: values?.modeOfPayment,
        isTaxable: values.isTaxable,
        fromDateTime: values?.fromDate,
        toDateTime: values?.toDate,
      },
    });
    if (response?.data?.detailSaleReport?.createdOn) {
      setReportData(response?.data?.detailSaleReport);
      setReportOpenState(true);
    } else {
      toast.error("Report data available");
    }
  };

  const handleReset = (setFieldValue: any) => {
    setFieldValue("user", []);
    setFieldValue("category", []);
    setFieldValue("items", []);
    setFieldValue("table", []);
    setFieldValue("modeOfPayment", []);
    setFieldValue("isTaxable", []);
    setFieldValue("fromDate", "");
    setFieldValue("toDate", "");
  };

  return (
    <>
      <DetailSalesReportComponent
        detailSalesReportLoading={detailSalesReportLoading}
        handleGenerateDetailSalesReport={handleGenerateDetailSalesReport}
        reportOpenState={reportOpenState}
        setReportOpenState={setReportOpenState}
        reportData={reportData}
        currency={settingsData?.basicWebsiteConfigurations?.currency}
        handleReset={handleReset}
      />
    </>
  );
};

export default DetailSalesReport;
